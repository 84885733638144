@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");

:root {
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: #129b37;
  --first-color-light: #eafdf8;
  --first-color-variant: #0a551e;
  --white-color: #f7f6fb;
  --gray-color: #dedde1;
  --second-color: #1a48e0;
  --second-color-variant: #102c88;
  --third-color: #bc1624;
  --third-color-variant: #760e17;
  --body-font: "Nunito", sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100;
}

.btn-primary {
  background-color: var(--second-color);
}
.btn-primary:hover {
  background-color: var(--second-color-variant);
}

.asset-background {
  background-color: var(--first-color);
  color: white;
}
.debt-background {
  background-color: var(--third-color);
  color: white;
}

.no-link-style {
  color: #212529;
}
.no-link-style:hover {
  color: var(--second-color);
}

.nav-logo-link {
  color: var(--first-color);
}

.primary-text {
  color: var(--first-color);
}

footer {
  background-color: var(--white-color);
}

/* Landing Page */

.landing-header {
  background-color: var(--first-color-variant);
}

/* Tables */

.even {
  background-color: "white";
}

.odd {
  background-color: var(--gray-color);
}

/* Nav and Sidebar */

*,
::before,
::after {
  box-sizing: border-box;
}

.body {
  position: relative;
  margin: var(--header-height) 0 0 0;
  padding: 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: 0.5s;
  background-color: var(--white-color);
}

.background {
  background-color: var(--white-color);
}

.body-nav {
  position: relative;
  margin: var(--header-height) 0 0 0;
  padding: 0 1rem;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: 0.5s;
  padding-left: calc(var(--nav-width));
  background-color: var(--white-color);
}

a {
  text-decoration: none;
  color: var(--third-color);
}

.link {
  text-decoration: none;
  color: var(--third-color);
}

a:hover {
  text-decoration: none;
  color: var(--third-color);
}

.header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: var(--white-color);
  z-index: var(--z-fixed);
  transition: 0.5s;
}

.header-toggle {
  color: var(--first-color);
  font-size: 1.5rem;
  cursor: pointer;
}

.header-img {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}

.header-img img {
  width: 40px;
}

.l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  background-color: var(--first-color);
  padding: 0.5rem 1rem 0 0;
  transition: 0.5s;
  z-index: var(--z-fixed);
}

.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.nav-logo,
.nav-link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
  color: var(--first-color);
}

.nav-logo {
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.8rem;
  color: var(--first-color);
}

.nav-logo-icon {
  font-size: 1.25rem;
  color: var(--white-color);
}

.nav-logo-name {
  color: var(--white-color);
  font-weight: 700;
}

.nav-link-bar {
  position: relative;
  color: var(--first-color);
  margin-bottom: 1.5rem;
  transition: 0.3s;
}
.nav-link {
  position: relative;
  color: var(--first-color-light);
  margin-bottom: 1.5rem;
  transition: 0.3s;
}

.nav-link:hover {
  color: var(--first-color-variant);
}
.nav-link-bar:hover {
  color: var(--first-color-variant);
}

.nav-icon {
  font-size: 1.25rem;
}

.show-nav {
  left: 0;
}

.body-pd {
  padding-left: calc(var(--nav-width) + 1rem);
}

.active {
  color: var(--white-color);
}

.active::before {
  content: "";
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: var(--white-color);
}

.height-100 {
  height: 100vh;
}

@media screen and (min-width: 768px) {
  .body-nav {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    padding-left: calc(var(--nav-width) + 2rem);
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
  }

  .header-img {
    width: 40px;
    height: 40px;
  }

  .header-img img {
    width: 45px;
  }

  .l-navbar {
    left: 0;
    padding: 1rem 1rem 0 0;
  }

  .show-nav {
    width: calc(var(--nav-width) + 156px);
  }

  .body-pd {
    padding-left: calc(var(--nav-width) + 188px);
  }
}
